import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NebularModule } from './nebular.module';
import { PriceCardComponent } from './price-card/price-card.component';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

@NgModule({
  declarations: [SidebarComponent, PriceCardComponent],
  imports: [CommonModule, NebularModule, HighlightModule],
  exports: [SidebarComponent, PriceCardComponent],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
  ],
})
export class SharedModule {}
