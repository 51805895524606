import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  NbAlertModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
} from '@nebular/theme';

@NgModule({
  imports: [
    CommonModule,
    NbSidebarModule.forRoot(),
    NbLayoutModule,
    NbMenuModule.forRoot(),
    NbAlertModule,
    NbLayoutModule,
  ],
  exports: [NbSidebarModule, NbLayoutModule, NbMenuModule, NbAlertModule],
})
export class NebularModule {}
