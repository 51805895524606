<div class="w-100 h-100 position-relative">
  <ng-particles
    class="position-absolute w-100 h-100"
    [id]="id"
    [options]="particlesOptions"
  ></ng-particles>
  <div class="row">
    <app-price-card
      class="col-10 offset-1"
      [cryptoLogo]="'../../../assets/icons8-bitcoin.svg'"
      [cryptoName]="'Bitcoin'"
      [price]="prices.btc"
    ></app-price-card>

    <app-price-card
      class="col-10 offset-1"
      [cryptoLogo]="'../../../assets/icons8-ethereum.svg'"
      [cryptoName]="'Ethereum'"
      [price]="prices.eth"
    ></app-price-card>
  </div>
</div>
