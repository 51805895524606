<nb-alert>
  <div
    class="w-100 d-flex flex-column justify-content-center align-item-center"
  >
    <div class="d-flex flex-column">
      <img [src]="cryptoLogo" [alt]="cryptoName" width="125" />
    </div>
    <div class="text-center position-absolute price">
      <h4>{{ cryptoName }}</h4>
      <h5 class="mb-0">
        {{ price | currency }}
      </h5>
    </div>
  </div>
</nb-alert>
