import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-price-card',
  templateUrl: './price-card.component.html',
  styleUrls: ['./price-card.component.scss'],
})
export class PriceCardComponent {
  @Input() cryptoLogo!: string;
  @Input() cryptoName!: string;
  @Input() price!: number;
}
