import { Component } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  items: NbMenuItem[] = [
    {
      title: 'Présentation',
      link: '/v1/presentation',
      icon: 'film-outline',
    },
    {
      title: 'Laboratoire',
      link: '/v1/laboratory',
      icon: 'pantone-outline',
    },
    {
      title: 'Blockchain v2',
      link: '/v2/blockchain',
      icon: 'layers-outline',
    },
    {
      title: 'Cryptomonnaie',
      link: '/v2/cryptocurrency',
      icon: 'people-outline',
    },
  ];
}
