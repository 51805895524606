import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

import { particles } from '../../particles/home.particles';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  id = 'tsparticles';

  particlesOptions = particles;

  prices: { btc: number; eth: number } = {
    btc: 0,
    eth: 0,
  };

  constructor(private apiService: ApiService) {}

  async ngOnInit(): Promise<void> {
    const timestamp = localStorage.getItem('timestamp');
    const now = new Date().getTime();
    this.prices.btc = Number(localStorage.getItem('btc')) || 0;
    this.prices.eth = Number(localStorage.getItem('eth')) || 0;

    if (!timestamp || now - +timestamp >= 120 * 1000) {
      const prices = await this.apiService.getPrices().toPromise();
      this.prices.btc = prices.bitcoin.cad;
      this.prices.eth = prices.ethereum.cad;
      localStorage.setItem('timestamp', now.toString());
      localStorage.setItem('btc', prices.bitcoin.cad.toString());
      localStorage.setItem('eth', prices.ethereum.cad.toString());
    }
  }

  particlesLoaded(container: any): void {
    console.log(container);
  }

  particlesInit(main: any): void {
    console.log(main);
  }
}
