<nb-layout>
  <nb-layout-header fixed>
    <img src="../assets/image.svg" alt="Logo" class="logo" />
    <h5 class="ml-3 mb-0">Blockchain</h5>
  </nb-layout-header>
  <nb-sidebar responsive [collapsedBreakpoints]="[]">
    <nb-menu [items]="items"></nb-menu>
  </nb-sidebar>

  <nb-layout-column class="colored-column-success">
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
