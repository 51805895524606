import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface IPrices {
  bitcoin: {
    cad: number;
  };
  ethereum: {
    cad: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  public getPrices(): Observable<IPrices> {
    return this.httpClient.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=ethereum%2Cbitcoin&vs_currencies=CAD`
    ) as Observable<IPrices>;
  }
}
